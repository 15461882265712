import React from 'react';
import './Technik.css';

class Technik extends React.Component {

  render() {
    return <div id='Technik'>
      <h2> Technik </h2>
      Bildträger für meine Arbeit ist eine recycelte Plastikfolie, die für den Malprozeß auf einen Keilrahmen aufgespannt wird, ähnlich der klassischen Leinwand.
      <br/><br/>
      Malmittel sind hauptsächlich Ölfarben, div. Lacke, Nylonfäden u.m.
      <br/><br/>
      Das fertige Bild wird anschließend vom Keilrahmen abgenommen und zwischen 2 Acrylplatten gelegt, die dann miteinander verschraubt werden.
      <br/><br/>
      Unter den fotografierten Bildern unter „Gemälde“ finden sich Beispiele für Bilder, die sich noch auf einem Keilrahmen befinden und Beispiele für bereits gerahmte Bilder.
      <br/><br/>
      Alle Bilder haben die gleiche Größe von 140x100cm.

      <div className="gridImgs">
        <img className="gridPicTop" src={require('../resources/others/IMG_0548.jpg')} />
        <img className="gridPicTop" src={require('../resources/others/IMG_0543.jpg')} />
        <img className="gridPic" src={require('../resources/others/IMG_0031.jpg')} />
        <img className="gridPic" src={require('../resources/others/IMG_0044.jpg')} />
      </div>

    </div>;
  }
}


export default Technik;
