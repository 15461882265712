import React from 'react';
import './Zeichnungen.css';
var listOfImages = [];

class Zeichnungen extends React.Component {

  importAll(r) {
    return r.keys().map(r);
  }

  componentWillMount() {
    listOfImages = this.importAll(require.context('../resources/Zeichnungen/', false, /\.(png|jpe?g|svg)$/));
  }

  componentDidMount(){
    for (const [index, value] of listOfImages.entries()) {
        document.getElementsByName(index)[0].style.animationDelay = index*100 + 'ms';
    }
  }

  fadeIn(name) {
      //obj.fadeIn(1000);

  }

  render() {

    const items = [];
    for (const [index, value] of listOfImages.entries()) {
      if(index < listOfImages.length - 2){
        items.push(<div className="DrawingItem" key={index}><img name={index} key={index} src={value} className="DrawingItemPic animate" alt="logo" /><p></p></div>);
        console.log(value.split("/")[value.split("/").length-1]);
      }else if(index == listOfImages.length - 2){
        items.push(<div className="DrawingItem bottomDrawingLeft" key={index}><img name={index} key={index} src={value} className="bottomDrawingPicLeft animate" alt="logo" /><p></p></div>);
        console.log(value.split("/")[value.split("/").length-1]);
      }else if(index == listOfImages.length - 1){
        items.push(<div className="DrawingItem bottomDrawingRight" key={index}><img name={index} key={index} src={value} className="bottomDrawingPicRight animate" alt="logo" /><p></p></div>);
        console.log(value.split("/")[value.split("/").length-1]);
      }

    }

    return <div id='Zeichnungen'>
      <h2> Archiv </h2>
      <div id="drawings">
        {items}

      </div>
    </div>;
  }
}


export default Zeichnungen;
