import React from 'react';
import './Start.css';

class Start extends React.Component {

  render() {
    return <div id='Start'>
        <div className='gridCell' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00015464_3.jpeg')} className='imgCell turnNegative' /> </div>
        <div className='gridCell' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00015465_35.jpeg')} className='imgCell turnNegative' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00015465_34.jpeg')} className='imgCell turnNegative' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006073_6.jpeg')} className='imgCell turnNegative' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006074_12.jpeg')} className='imgCell turnPositive' /> </div>
        <div className='gridCell' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006074_18.jpeg')} className='imgCell turnPositive' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006074_19.jpeg')} className='imgCell turnPositive' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006074_16.jpeg')} className='imgCell turnPositive' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00015465_32.jpeg')} className='imgCell turnNegative' /> </div>
        <div className='gridCell hideOnDesktop' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006073_26.jpeg')} className='imgCell turnNegative ' /> </div>

        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006072_2.jpeg')} className='imgCell turnNegative' /> </div>
        <div className='gridCell' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006072_1.jpeg')} className='imgCell turnNegative ' /> </div>
        <div className='gridCell' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006072_20.jpeg')} className='imgCell turnNegative' /> </div>
        <div className='gridCell hideOnDesktop' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006074_12.jpeg')} className='imgCell turnPositive' /> </div>
        <div className='gridCell hideOnDesktop' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00015464_19.jpeg')} className='imgCell turnNegative' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00015465_6.jpeg')} className='imgCell turnNegative' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00015465_11.jpeg')} className='imgCell turnPositive' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00015464_21.jpeg')} className='imgCell turnPositive' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00015465_18.jpeg')} className='imgCell turnNegative' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00015465_22.jpeg')} className='imgCell turnPositive' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00015465_8.jpeg')} className='imgCell turnNegative' /> </div>

        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006073_25.jpeg')} className='imgCell turnNegative' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00015464_11.jpeg')} className='imgCell turnNegative' /> </div>
        <h2> Birgit&nbsp;Walter </h2>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006073_23.jpeg')} className='imgCell turnNegative' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00015464_19.jpeg')} className='imgCell turnNegative' /> </div>

        <div className='gridCell hideOnDesktop' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00015465_32.jpeg')} className='imgCell turnNegative' /> </div>
        <div className='gridCell hideOnDesktop' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006073_25.jpeg')} className='imgCell turnNegative' /> </div>
        <div className='gridCell hideOnDesktop' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00015465_6.jpeg')} className='imgCell turnNegative' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006074_20.jpeg')} className='imgCell turnPositive' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006073_26.jpeg')} className='imgCell turnNegative ' /> </div>
        <div className='gridCell' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006072_14.jpeg')} className='imgCell turnNegative' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006074_15.jpeg')} className='imgCell turnPositive' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00015465_30.jpeg')} className='imgCell turnNegative' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00015464_17.jpeg')} className='imgCell turnNegative' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006074_5.jpeg')} className='imgCell turnPositive' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006073_21.jpeg')} className='imgCell turnNegative' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00015465_10.jpeg')} className='imgCell turnPositive' /> </div>

        <div className='gridCell hideOnDesktop' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006072_2.jpeg')} className='imgCell turnNegative' /> </div>
        <div className='gridCell hideOnDesktop' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006074_5.jpeg')} className='imgCell turnPositive' /> </div>
        <div className='gridCell hideOnDesktop' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00015465_30.jpeg')} className='imgCell turnNegative' /> </div>
        <div className='gridCell hideOnDesktop' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006073_21.jpeg')} className='imgCell turnNegative' /> </div>

        <div className='gridCell hideOnDesktop padLeftOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00015464_11.jpeg')} className='imgCell' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006072_36.jpeg')} className='imgCell turnNegative' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006073_18.jpeg')} className='imgCell turnNegative ' /> </div>
        <div className='gridCell overTwoOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006072_12.jpeg')} className='imgCell turnNegative' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006072_24.jpeg')} className='imgCell turnNegative ' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006073_2.jpeg')} className='imgCell turnNegative' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006073_24.jpeg')} className='imgCell turnNegative ' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006074_23.jpeg')} className='imgCell turnNegative ' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00015464_13.jpeg')} className='imgCell turnNegative ' /> </div>
        <div className='gridCell hideOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00006072_5.jpeg')} className='imgCell turnNegative' /> </div>
        <div className='gridCell hideOnDesktop padRightOnMobile' onContextMenu={(e)=> e.preventDefault()}> <img src={require('../resources/MainPics/00015465_22.jpeg')} className='imgCell' /> </div>
    </div>;
  }

}

export default Start;