import React from 'react';
import './Ausstellungen.css';

class Ausstellungen extends React.Component {

  render() {
    return <div id='Ausstellungen'>
      <div id='preise' className='kategorie'>
        <h2>Preise</h2>
        <div>
          1995 'New Quality', Stadt Wien <br/>
  		    1990 'Neue Impulse und Ansätze in der modernen Malerei', Köln
        </div>
      </div>
      <div id='ankaeufe' className='kategorie'>
        <h2>Ankäufe</h2><br/>
        <div id='ankaeufePrivat' className='unterkategorie'>
          <h3>Privat</h3>
          <p>Stephen Strother, London 2</p>
          <p>George Shiffard, New York 3</p>
          <p>Stolz, Bonn 1</p>
          <p>Dr. J. Egner, Bonn 2</p>
          <p>Ch. L. Attersee, Wien 2</p>
          <p>Dr. n. Lehner, Wien 1</p>
          <p>Dr. Wagner, Wien 2</p>
        </div>
        <div id='ankaeufeOeffentlich' className='unterkategorie'>
          <h3>Öffentlich</h3>
          <p>Neue Galerie, Graz 1</p>
          <p>Kunsthalle (DW), Köln 1</p>
          <p>Österr. Nationalbank, Wien 3</p>
          <p>Lentos Museum, Linz 2</p>
          <p>Wirtschaftskammer, Graz 1</p>
        </div>
      </div>
      <div id='einzelAusstellungen' className='kategorie'>
        <h2>Einzelausstellungen (Auswahl)</h2>
        <ul>
        <li>- Grazer Kongress, Graz</li>
        <li>- Galerie Ariadne, Wien</li>
        <li>- Galerie Villa Rolandseck (Europ. Kulturzentrum), Bonn
          in Zusammenarbeit mit der Österr. Botschaft</li>
        <li>- Kunsthalle Köln (DW), Köln</li>
        <li>- Galerie am Stubentor, Wien</li>
        <li>- Galerie Atelier, Wien</li>
        <li>- Galerie Raif, Eggersdorf</li>
        <li>- Galerie Neostadia, Wr. Neustadt
          Installation, Ausstellung und öffentliche Kunstdebatte mit
          „Kunstmaschine“ Franz Morak</li>
        <li>- Galerie Forum Kunst Hernals, Wien</li>
        </ul>
      </div>

      <div id='gemeinschaftsAusstellungen' className='kategorie'>
        <h2>Gemeinschaftsausstellungen (Auswahl)</h2>
        <ul>
        <li>- 21er Haus, Museum für zeitgenössische Kunst, Wien
         „no man´s dream, no mainstream“, Dr. Ingried Brugger</li>
    		<li>- Galerie Villa Rolandseck, Bonn</li>
    		<li>- Schatzhaus Salzburg „Bild Lust – Young Art“,
         Suppan Fine Arts, Salzburg</li>
    		<li>- Niederösterr. Landesstudio, St. Pölten</li>
    		<li>- Wanderausstellung: Wien, Mailand, Amsterdam</li>
    		<li>- Palais Harrach, Galerie Suppan Contemporaray, Wien</li>
    		<li>- Galerie Suppan, Wien</li>
    		<li>- Galerie Schloß Wolkersdorf, Wolkersdorf</li>
    		<li>- Art Vienna, Technisches Museum, Galerie Suppan, Wien</li>
    		<li>- Galerie Ariadne „Fe/Male-Toleranz-Akzeptanz“, Wien</li>
    		<li>- Galerie Ariadne „A.-Splitter“, Wien</li>
    		<li>- Museum für Angewandte Kunst „Kunst Wien“, Wien</li>
    		<li>- Galerie Villa Rolandseck (Europ. Kulturzentrum), Bonn</li>
    		<li>- Kunstbahnhof Berlin Westend „Europa vor Ort II“
         Dr. Karl Hofer Gesellschaft, Berlin</li>
    		<li>- Galerie Adriadne „Selection one“, Wien</li>
    		<li>- Galerie Wolkersdorf</li>
    		<li>- Galerie Station 3 „Soho Ottakring“, Wien</li>
    		<li>- Kunsthalle Attersee „Der Attersee in der Malerei des 20. Jahrhunderts“,
         Prof. Peter Baum, Attersee</li>
    		<li>- Galerie am Stubentor, Wien</li>
    		<li>- Kunsthalle Attersee „Weibersommer“
         Prof. Peter Baum, Attersee</li>
    		<li>- Intern. Biennale of humor, Sofia</li>
    		<li>- Unesco „new members of the international association of art for
         professional artists, Paris</li>
    		<li>- Drei Sommerprojekte in Eggersdorf, Steiermark und in Eltendorf und Oberwart, Burgenland mit
         Künstlern aus Österreich, Deutschland, Frankreich, Luxenburg, Schweiz</li>
    		<li>- Projekt im öffentlichen Raum, Installation (9x3m), Votivpark, Wien</li>
    		<li>- Rauminstallation, Sponsor - Cafe Stein, Wien</li>
    		<li>- Portraitvideo, Sponsor – Woka, Wien</li>
    		<li>- Heeresgeschichtliches Museum „Zierschlacht“, Wien</li>
        </ul>
      </div>

    </div>;
  }
}


export default Ausstellungen;
