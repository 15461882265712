import React from 'react';
import './ItemView.css';
var listOfImages = [];
var path = "";

class ItemView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: props.name };
    this.path = '../../resources/GalleryPics/'+this.props.name+'/';
  }

  importAll(r) {
    return r.keys().map(r);
  }

  componentWillMount() {
    listOfImages = this.importAll(require.context('../../resources/GalleryPics/', false, /\.(png|jpe?g|svg)$/));
  }

  closeItem(e) {
    e.stopPropagation();
    var x = document.getElementsByName(this.props.name);
    x[0].style.display = "none";
    console.log(x);
  }

  render() {
    //Loop over each picture
    const elements = ['Item1', 'Item2', 'Item3'];

    const items = [];
    const titles = [];

    var name = "";
    var labelName = "";
    for (const [index, value] of listOfImages.entries()) {
      name = (value.split("/")[value.split("/").length-1]).split(".")[0];
      if(name.endsWith(this.props.name)){
        labelName = this.props.name;
        items.push(<div key={index} className="itemPreview"><img src={value} alt="logo" className="itemPreviewPic"/></div>);
        titles.push(<p key={index}>{labelName}</p>);
      }
    }

    return (
      <div className='previewArea' name={this.props.name} onClick={(e) => this.closeItem(e)}>
        <div className='previewPopUp' onClick={(e) => e.stopPropagation()}>
          <div className="headerPopUp">
            <div className="close" onClick={(e) => this.closeItem(e)}/>
          </div>
          {items}
          <div id='previewTitleList'>
            {titles}
            <p> (130*100 cm) </p>
          </div>
        </div>
      </div>
    )
  }
}

export default ItemView;
