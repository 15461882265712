import React from 'react';
import { Link } from "react-router-dom";
import './Menu.css';

class Menu extends React.Component {

  render() {
    return (
    <div id='Menu'>
      <Link to="/Gallery"> <h2>Gemälde</h2> </Link>
      <Link to="/Technik"> <h2>Technik</h2> </Link>
      <Link to="/Zeichnungen"> <h2>Zeichnungen</h2> </Link>
      <Link to="/Ausstellungen"> <h2>Ausstellungen</h2> </Link>
      <Link to="/Biography"> <h2>Biografie</h2> </Link>
      <Link to="/Kontakt"> <h2>Kontakt</h2> </Link>
    </div>

    );
  }
}


export default Menu;
