import React from 'react';
import './Biography.css';

class Biography extends React.Component {

  render() {
    return <div id='Biography'>
      <h2> Birgit Walter </h2>
      <br />
      <br />
      <br />
      <h3> Lebenslauf: </h3>

        geboren in Graz, lebt und arbeitet seit 1991 in Wien
        <br />
        <br />
        Hochschule für Angewandte Kunst,
        <br />
        Meisterklasse für Malerei, experimentelles Gestalten u. Animationsfilm
        <br />
        bei Prof. Ch. L. Attersee, Wien
        <br />
        <br />
        Magistra der Philosophie, Universität Wien
        <br />
        (Literatur, Geschichte, Sozial- und Wirtschaftsgeschichte)
        <br />
        <br />
        Gründung, Aufbau und CEO des intern. Instituts „Das Sprachenstudio“ und Kooperationspartnerin vom Innenministerium,
        <br /> Unterrichtsministerium, Auslandsministerium und der Stadt Wien, Österreich
        <br />
        <br />
        freischaffende Malerin: Gestaltung und Beteiligung an Ausstellungen und Projekten im In- und Ausland

    </div>;
  }
}


export default Biography;
