import React from 'react';
import ItemView from '../ItemView/ItemView.js';
import './GalleryGrid.css';
import colorSpec from '../../resources/GalleryPreview/colorSpec.json';
var listOfImages = [];

class GalleryGrid extends React.Component {


  importAll(r) {
    return r.keys().map(r);
  }

  componentWillMount() {
    listOfImages = this.importAll(require.context('../../resources/GalleryPics/', false, /\.(png|jpe?g|svg)$/));
  }

  openItem(v) {
    var x = document.getElementsByName(v);
    console.log(v);
    x[0].style.display = "block";
  }

  render() {
    var previewImages = {};
    var names = {};
    var groupName = "";
    var name = "";

    for (const [index, value] of listOfImages.reverse().entries()) {
      groupName = (value.split("/")[value.split("/").length-1]).split(".")[0].split("_")[0];
      name = (value.split("/")[value.split("/").length-1]).split(".")[0].split("_")[1];
      console.log(groupName);
      names[index] = name;
    }

    return (
      <div id='Gallery'>
      <h2> Gallerie </h2>
      <div id='GalleryGrid'>
          {listOfImages.map((item, i) => (
            <div className="GalleryItem" key={i} onClick={() => this.openItem(names[i])}>
                <img className="previewImage" src={item}></img>
                <div className="GalleryItemPicFrame"></div>
                <hr/>

                <ItemView name={names[i]}/>
            </div>
          ))}
      </div>
      </div>
    )
  }
}
//<p>{names[i]}</p>
export default GalleryGrid;
