import React from 'react';
import './Header.css';
import Menu from '../Menu/Menu.js';
import { Link } from "react-router-dom";

class Header extends React.Component {

    componentDidMount() {
        var width = this.getWidth();
        if(width > 720){
            document.getElementById("Header").style.width = "0px";
        }else{
            document.getElementById("Header").style.height = "0px";
        }
    }

    getWidth() {
      return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      );
    }

    getHeight() {
      return Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.documentElement.clientHeight
      );
    }

    /* Set the width of the sidebar to 0 and the left margin of the page content to 0 */
    changeNavOpen = () => {
        var width = this.getWidth();
        if(width > 720){
            document.getElementById("Header").style.width = "20%";
        }else{
            document.getElementById("Header").style.height = "100%";
            console.log("smol");
        }

    }

    /* Set the width of the sidebar to 0 and the left margin of the page content to 0 */
    changeNavClose = () => {
        var width = this.getWidth();
        if(width > 720){
            document.getElementById("Header").style.width = "0px";
        }else{
            document.getElementById("Header").style.height = "0px";
            console.log("smol close");
        }
    }

    /* Set the width of the sidebar to 0 and the left margin of the page content to 0 */
    closeForMobile = () => {
        var width = window.screen.width;
        if(width <= 720){
            document.getElementById("Header").style.height = "0px";
        }
    }


  render() {
    return (
    <div id='HeaderWrap'>
    <div id='Header' onClick={this.closeForMobile}>
        <a href="javascript:void(0)" className="closebtn" id="togglerClose" onClick={this.changeNavClose}>&times;</a>
        <Link to='/'> <h1>Birgit Walter</h1> </Link>
        <p id="subtitle"> Malerin </p>
        <br /> <br /><br /> <br />
        <Menu />
    </div>
    <div id='collapser'>
        <a href="javascript:void(0)" className="openbtn" id="togglerOpen" onClick={this.changeNavOpen}>&equiv;</a>
    </div>
    </div>);
  }
}


export default Header;
