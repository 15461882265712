import React from 'react';
import './Footer.css';

class Footer extends React.Component {

  render() {
    return <div id='Footer'> (c) 2021  Pascal Poremba </div> ;
  }
}

export default Footer;
