import React from 'react';
import ReactDOM from 'react-dom';
import './style.css';
import Header from './Components/Header/Header';
import Start from './Pages/Start';
import GalleryGrid from './Components/GalleryGrid/GalleryGrid';
import ItemView from './Components/ItemView/ItemView';
import Biography from './Pages/Biography';
import Technik from './Pages/Technik';
import Zeichnungen from './Pages/Zeichnungen';
import Ausstellungen from './Pages/Ausstellungen';
import Kontakt from './Pages/Kontakt';
import Footer from './Components/Footer/Footer';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
//import { HashRouter as Router, Route } from 'react-router-dom';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <div id="main">
        <Header />
        <Route path="/" exact component={Start} />
        <Route path="/Gallery" exact component={GalleryGrid} />
        <Route path="/Biography" component={Biography} />
        <Route path="/Technik" component={Technik} />
        <Route path="/Zeichnungen" component={Zeichnungen} />
        <Route path="/Ausstellungen" component={Ausstellungen} />
        <Route path="/Kontakt" component={Kontakt} />
        <Footer />
      </div>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
